<template>
  <el-dialog title="" width="480px" :visible.sync="_show" :close-on-click-modal=false :close-on-press-escape=false :show-close=false>
    <div>
      <div class="top-msg flex-cent-col">
        <img src="@/assets/images/expire.png" alt="">
        <div class="fz20 expire-title">应用已到期</div>
        <div>当前版本：V1.0</div>
      </div>
<!--      <div class="father">-->
<!--        <div class="expand-btn">续费</div>-->
<!--      </div>-->
      <div class="flex-cent-col bot-msg link-serve">
        <img :src="kf_qr||require('@/assets/images/WeChat_code.png')" alt="" class="wx-code">
        <div class="tips fz12">扫一扫识别二维码，添加客服企业微信</div>
      </div>
      <div class="flex-cent-col bot-msg">
        <div class="tel fz18">4000-2828-80</div>
        <div class="bot-tip">咨询商务或客服</div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
name: "expire",
  props: ["show"],
  data(){
    return{
      visible: true, //弹窗显示隐藏
    }
  },
  computed:{
        kf_qr(){
          if(this.$store.state.kfurl||sessionStorage.getItem('appInfo')){
            return this.$store.state.kfurl||JSON.parse(sessionStorage.getItem('appInfo')).kefu_qr
          }else{
            return ''
          }
        },
    _show: {
      get: function () {
        return this.show;
      },
      set: function (val) {
        this.$emit("update:show", val);
      }
    },
  },
  created(){
    this.getExpireMsg();
  },
  methods:{
    getExpireMsg(){
      this.expireMsg=JSON.parse(sessionStorage.getItem('expireMsg'))
    },
  }
}
</script>

<style lang="less" scoped>
/deep/.el-dialog{
  .el-dialog__header{
    background: #0A86EF;
  }
  .el-dialog__body{
    padding: 0;
  }
  .top-msg{
    background: #0A86EF;
    color:#fff;
    padding: 35px 0 50px;
    .expire-title{
      padding:16px 0 4px;
    }
  }
  .father{
    position: relative;
    .expand-btn{
      width: 243px;
      height: 44px;
      font-size: 18px;
      text-align: center;
      line-height: 44px;
      color:#fff;
      background: #F5AC40;
      border-radius:2px ;
      position: absolute;
      top:-22px;
      left:119px;
    }
  }
  .link-serve{
    margin: 50px 28px 20px;
    border-bottom: 1px dashed #DCE1E6;
    .tips{
      padding: 23px 0;
    }
  }
  .bot-msg{
    background: #fff;
    .wx-code{
      width: 136px;
    }
    .tel{
      color: #0A86EF;
    }
    .bot-tip{

      color: #999999;
      padding: 4px 0 32px;
    }
  }
}
</style>