import $http from "@/utils/request.js";
// import qs from "qs";

// 获取token
export function getWebsiteToken() {
    return $http.post('/getWebsiteToken')
}

// 获取goods_sn
export function getWebsiteGoodsSn() {
    return $http.post('/getWebsiteGoodsSn')
}

// 获取goods_sn
export function getWebsiteUri() {
    return $http.post('/getCommonPyUri')
}



