<template>
  <div class="index">
    <div class="left-item" v-loading.fullscreen.lock="loading">
      <div class="main-center">
        <div class="welcome">
          <span v-if="allIsWx3 == 'true'"
            ><wxComponent type="userName" :openid="userId"
          /></span>
          <span class="user-name-zijian" v-else>{{ username }}</span
          >，欢迎进入自定义工作台系统
        </div>
        <div class="set-tips flex-bet">
          <div class="flex-start">
            <img src="@/assets/images/titiletop.png" alt="" class="set-icon" />
            <div class="set-msg">
              <div>现在，你可以快速定制企业自己的企微工作台了</div>
              <div class="fz14">
                无代码快速接入，多种系统模板自由选择，满足不同员工不同数据场景
              </div>
            </div>
          </div>
          <el-button
            size="small"
            class="set-right"
            v-if="diy_workbench"
            @click="setModel"
            >马上去设置</el-button
          >
        </div>
        <div class="set-title fz14" v-show="modelData.length > 0">
          工作台模板
        </div>
        <div class="model-list flex-start">
          <div
            class="model-item"
            v-for="(item, index) in modelData"
            :key="index"
          >
            <vue-hover-mask>
              <div class="top-bg">
                <div
                  v-if="item.is_system == 1"
                  class="flag fz12 examples"
                  style="background: #fff; color: black"
                >
                  示例
                </div>
                <div
                  v-if="item.is_system == 2"
                  class="flag fz12 model-flag"
                  style="background: #fccf53"
                >
                  免费
                </div>
                <div
                  v-else-if="item.is_system == 0 && item.temp_status === 1"
                  class="flag fz12 top-flag"
                >
                  已启用
                </div>
                <div
                  v-else-if="item.is_system == 0 && item.temp_status === 0"
                  class="flag fz12 no-flag"
                >
                  未发布
                </div>
                <div
                  v-else-if="item.is_system == 0 && item.temp_status === 2"
                  class="flag fz12 no-flag"
                >
                  未启用
                </div>
                <div
                  v-else-if="item.is_system == 0 && item.temp_status === 3"
                  class="flag fz12 del-flag"
                >
                  已删除
                </div>
                <div class="bg-content">
                  <img
                    src="@/assets/images/bg_top.png"
                    alt=""
                    class="top-bg-img"
                  />
                  <div v-if="item.is_system > 0">
                    <img
                      v-for="(app, i) in item.modular"
                      :key="i"
                      :src="app.show_image"
                      alt=""
                      class="model-item-img"
                    />
                  </div>
                  <div v-else>
                    <img
                      v-for="(app, i) in item.modular"
                      :key="i"
                      :src="getAppBg(app.modular_type, item.is_system)"
                      alt=""
                      class="model-item-img"
                    />
                  </div>
                </div>
              </div>
              <template v-slot:action>
                <el-dropdown
                  trigger="click"
                  size="small"
                  @command="handleCommand"
                  v-if="item.is_system !== 1"
                >
                  <div class="edit-more el-dropdown-link">
                    <i class="el-icon-more"></i>
                  </div>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      :command="beforeCom(item, 'a')"
                      v-show="item.temp_status === 1 && use_template"
                      :disabled="item.temp_status !== 1"
                      >关闭</el-dropdown-item
                    >
                    <el-dropdown-item
                      :command="beforeCom(item, 'u')"
                      v-show="item.temp_status === 2 && use_template"
                      >启用</el-dropdown-item
                    >
                    <el-dropdown-item
                      :command="beforeCom(item, 'b')"
                      v-show="item.temp_status === 3"
                      >恢复</el-dropdown-item
                    >
                    <el-dropdown-item
                      :command="beforeCom(item, 'c')"
                      v-show="item.temp_status === 0 || item.temp_status === 2"
                      v-if="del_template"
                      >删除</el-dropdown-item
                    >
                    <el-dropdown-item
                      :command="beforeCom(item, 'd')"
                      v-show="item.temp_status === 3"
                      v-if="del_template"
                      >彻底删除</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
                <el-button
                  v-if="edit_template && diy_workbench && item.is_system !== 1"
                  class="edit-model"
                  @click="handleEdit(item.id, 1)"
                  size="small"
                  round
                  >编辑</el-button
                >
                <div
                  class="is-system flex-cent-col"
                  v-if="item.is_system == 1 && diy_workbench && add_template"
                >
                  <el-button
                    class="edit-model"
                    @click="handleEdit(item.id, 2)"
                    size="small"
                    round
                    >查看</el-button
                  >
                  <el-button
                    class="edit-model"
                    @click="handleEdit(item.id, 3)"
                    size="small"
                    round
                    >复制</el-button
                  >
                </div>
              </template>
            </vue-hover-mask>
            <div class="bot-name">{{ item.temp_name }}</div>
          </div>
        </div>
        <div class="set-title fz14">应用推荐</div>
        <div class="app-list flex-bet">
          <div
            class="app-item flex-start"
            v-for="(item, index) in appList"
            :key="index"
          >
            <img :src="item.img" alt="" class="set-icon" />
            <div class="item-tips">
              <div class="fz14">{{ item.title }}</div>
              <div class="fz12 item-msg">{{ item.tips }}</div>
            </div>
            <el-popover placement="top" width="170" trigger="click">
              <div class="flex-start-col">
                <img
                  :src="item.code"
                  alt=""
                  class="wx-code"
                  style="width: 130px; height: 130px; margin-bottom: 12px"
                />
                <div class="fz14">打开企业微信</div>
                <div class="fz14">扫码免费试用</div>
              </div>
              <el-button size="mini" slot="reference" class="item-right"
                >开 通</el-button
              >
            </el-popover>
          </div>
        </div>
      </div>
    </div>
    <div class="right-item">
      <div class="main-info">
        <div class="flex-start-col">
          <img src="@/assets/images/com-logo.png" alt="logo" />
          <div class="company">{{ company }}</div>
          <div class="line"></div>
        </div>
        <div class="use-data flex-bet">
          <div>
            <span>人数：</span>
            <el-tooltip
              class="item"
              effect="dark"
              content="使用人数"
              placement="top"
            >
              <span class="use-number">&nbsp;{{ useUser }}/&nbsp;</span>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="购买人数"
              placement="top"
            >
              <span class="use-number">&nbsp;{{ buyUser }}/&nbsp;</span>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="总人数"
              placement="top"
            >
              <span class="use-number">&nbsp;{{ totalUser }}&nbsp;</span>
            </el-tooltip>
          </div>
          <div>
            <span>部门：</span>
            <span class="use-number">{{ totalDept }}个</span>
          </div>
        </div>
        <div style="display: flex; flex-wrap: wrap; align-items: center">
          <div class="use-title">有效期至</div>
          <!-- <div style="margin-left: 20px" v-if="buttonNewopen">
            <el-button
              type="primary"
              @click="clickNewopen"
              size="small"
              style="
                background: linear-gradient(180deg, #0082ef 0%, #006ac4 100%);
              "
            >
              {{ buttonNewopen }}
            </el-button>
          </div>
          <div style="margin-left: 20px" v-if="buttonRenew">
            <el-button
              type="primary"
              @click="clickRenew"
              size="small"
              style="
                background: linear-gradient(180deg, #0082ef 0%, #006ac4 100%);
              "
            >
              {{ buttonRenew }}
            </el-button>
          </div>
          <div style="margin-left: 20px" v-if="buttonExpand">
            <el-button
              type="primary"
              @click="clickExpand"
              size="small"
              style="
                background: linear-gradient(180deg, #0082ef 0%, #006ac4 100%);
              "
            >
              {{ buttonExpand }}
            </el-button>
          </div> -->
        </div>
        <div class="use-time" style="display:flex;align-items:center;">
          <span v-if="type_name">{{ type_name }} / </span
          ><span>{{ expireTime }}</span
          >（剩余<span class="last-time">&nbsp;{{ surplusDays }}&nbsp;</span
          >天）
          <img src="@/assets/images/customer_service.svg" style="cursor:pointer;" @click="showlink = true" alt="">
        </div>
        <!-- <div class="expend">
          <div>请联系商务或客服进行续费</div>
          <div class="link-open flex-bet" @click="showlink = true">
            <div class="flex-start">
              <img src="@/assets/images/link.png" class="link-icon" />
              <div style="color: #1890ff">联系客服</div>
            </div>
            <div class="fz12">点击咨询</div>
          </div>
        </div> -->
      </div>
    </div>
    <el-dialog
      title="确定启用并推送给员工吗？"
      :visible.sync="dialogVisible"
      width="30%"
    >
      <span
        >推送成功后，员工的企业微信-工作台页面会自动刷新，数据推送可能会有延时</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="modelLoading" @click="getUseModel"
          >启用并推送</el-button
        >
      </span>
      <!--    您可以去“<el-button type="text" @click="goToAsync">设置—任务列表</el-button>”中查看推送进度-->
    </el-dialog>
    <expire :show.sync="showExpire"></expire>
    <link-service :show.sync="showlink"></link-service>

    <el-dialog
      :title="`${
        typeName === 'renewal'
          ? '续费'
          : typeName === 'expand'
          ? '扩容'
          : typeName === 'upgrade'
          ? '升级'
          : typeName === 'newopen'
          ? '新开'
          : typeName === 'mixed'
          ? '混合'
          : ''
      } `"
      :visible.sync="dialogVisibleRenew"
      width="550px"
      :modal-append-to-body="false"
    >
      <iframe
        :style="`height:${
          typeName === 'renewal'
            ? '390px'
            : typeName === 'expand'
            ? '400px'
            : typeName === 'upgrade'
            ? '420px'
            : typeName === 'newopen'
            ? '450px'
            : typeName === 'mixed'
            ? '650px'
            : ''
        }`"
        frameborder="0"
        width="100%"
        :src="`${website_uri}?product_pay_cate=${typeName}&Paid-Token=${Paid_Token}&goods_sn=${goods_sn}&source=${source}&from_userid=${from_userid}`"
      ></iframe>
    </el-dialog>
  </div>
</template>

<script>
import VueHoverMask from 'vue-hover-mask'
import expire from '@/components/expire'
import LinkService from '@/components/linkService'
import {
  getUserInfo,
  indexGetList,
  closeModel,
  delModel,
  deletes,
  useModel,
  recoveryModel
} from '@/api/workbench'
import wxComponent from '@/components/wx'
import { getConfigApi } from '../../api/config'
import { otherBrowser, wxAgentConfig, wxConfig } from '../../utils/wx'
import {
  getWebsiteToken,
  getWebsiteGoodsSn,
  getWebsiteUri
} from '../../api/payment'
export default {
  name: 'Index',
  data() {
    return {
      from_userid:null,
      source: 3,
      buttonNewopen: null,
      buttonRenew: null,
      buttonExpand: null,
      website_uri: 'http://general-payment-page.mgtx-tech-dev.mgtx.com.cn/',
      goods_sn: null,
      Paid_Token: null,
      typeName: null, // renewal、expand、upgrade、newopen、mixed
      dialogVisibleRenew: false,
      modelLoading: false,
      modelData: [],
      loading: false,
      showExpire: false, //应用到期弹窗
      showlink: false, //联系客服
      username: '',
      totalDept: 0,
      useUser: 0,
      buyUser: 0,
      totalUser: 0,
      company: '',
      userId: '',
      type_name: '',
      expireTime: '--',
      surplusDays: '--',
      appList: [
        {
          img: require('@/assets/images/xinwenl.png'),
          title: '新闻公告',
          tips: '单位视角，人文关怀',
          code: require('@/assets/images/xinwen.png')
        },
        {
          img: require('@/assets/images/renwul.png'),
          title: '轻松任务',
          tips: '远程协同，任务高效协作',
          code: require('@/assets/images/renwu.png')
        },
        {
          img: require('@/assets/images/baocanl.png'),
          title: '报餐系统',
          tips: '精准备餐，减少浪费',
          code: require('@/assets/images/dingcan.png')
        },
        {
          img: require('@/assets/images/huihual.png'),
          title: '会话存档',
          tips: '员工与客户会话内容合规存档',
          code: require('@/assets/images/huihua.png')
        },
        {
          img: require('@/assets/images/circle.png'),
          title: '企业朋友圈',
          tips: '企业微信专属的朋友圈营销神器',
          code: require('@/assets/images/pengyouquan.png')
        },
        {
          img: require('@/assets/images/money.png'),
          title: '工资条',
          tips: '批量发送工资条，全程加密，杜绝泄露',
          code: require('@/assets/images/gongzitiao.png')
        }
      ],
      dialogVisible: false,
      allIsWx3: true
    }
  },
  components: {
    wxComponent,
    VueHoverMask,
    expire,
    LinkService
  },
  created() {
    this.setTimer()
    this.from_userid=JSON.parse(sessionStorage.getItem('userInfo')).userId
  },
  mounted() {
    if (process.env.NODE_ENV === 'production') {
      setTimeout(() => {
        this.getUser()
      }, 1500)
    }
  },
  computed: {
    diy_workbench() {
      return this.$store.state.listData.diy_workbench
    },
    edit_template() {
      return this.$store.state.listData.edit_template
    },
    del_template() {
      return this.$store.state.listData.del_template
    },
    use_template() {
      return this.$store.state.listData.use_template
    },
    add_template() {
      return this.$store.state.listData.add_template
    },
    getAppBg() {
      return function (type, system) {
        if (system == 1) {
          if (type == 'keydata') {
            return require('@/assets/images/keydata.png')
          } else if (type == 'image') {
            return require('@/assets/images/img.png')
          } else if (type == 'list') {
            return require('@/assets/images/list.png')
          } else {
            return require('@/assets/images/webview.png')
          }
        } else {
          if (type == 'keydata') {
            return require('@/assets/images/model1.png')
          } else if (type == 'image') {
            return require('@/assets/images/model2.png')
          } else if (type == 'list') {
            return require('@/assets/images/model3.png')
          } else {
            return require('@/assets/images/model4.png')
          }
        }
      }
    }
  },
  methods: {
    // 获取appInfo
    gitAppInfo() {
      this.appInfo = JSON.parse(sessionStorage.getItem('appInfo'))
      if (this.appInfo) {
        if (
          this.appInfo.version_code === 'trial' ||
          this.appInfo.version_code === 'free'
        ) {
          this.buttonNewopen = '开通'
        } else if (this.appInfo.version_code === 'standard') {
          this.buttonRenew = '续费'
          this.buttonExpand = '扩容'
        }
      }
    },
    // 获取token、goods_sn
    getTokenGoods_sn() {
      // this.Paid_Token='c03c752face0eac8a0bedf2384972777961725d5fbd92d8532a2f5e41236b879'
      // this.goods_sn='QiUKY7PgW20181201115007WdcASkxtQ'
      // this.website_uri='http://localhost:8081'
      // this.from_userid='admin'
      // this.dialogVisibleRenew = true

      // token
      getWebsiteToken().then((res) => {
        if (res.code === 200) {
          this.Paid_Token = res.data.token
          // 获取goods_sn
          getWebsiteGoodsSn().then((res) => {
            if (res.code === 200) {
              this.goods_sn = res.data.goods_sn
              // 获取主站域名接口
              getWebsiteUri().then((res) => {
                if (res.code === 200) {
                  // this.website_uri = res.data.website_uri
                  this.dialogVisibleRenew = true
                } else {
                  this.$message.error(res.message)
                }
              })
            } else {
              this.$message.error(res.message)
            }
          })
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 点击开通（新开）
    clickNewopen() {
      this.typeName = 'newopen'
      this.getTokenGoods_sn()
    },
    // 点击续费
    clickRenew() {
      this.typeName = 'renewal'
      this.getTokenGoods_sn()
    },
    // 点击扩容
    clickExpand() {
      this.typeName = 'expand'
      this.getTokenGoods_sn()
    },
    beforeCom(row, command) {
      return {
        row: row,
        command: command
      }
    },
    handleCommand(command) {
      //选择启用禁用删除模板
      switch (command.command) {
        case 'a': //关闭
          this.closeThisModel(command.row.id)
          break
        case 'b': //恢复
          this.recovery(command.row.id)
          break
        case 'c': //删除
          this.handleDelModel(command.row.id, 1)
          break
        case 'd': //彻底删除
          this.handleDelModel(command.row.id, 2)
          break
        case 'u': //启用
          this.isUseModel(command.row.id)
          break
      }
    },
    handleEdit(id, type) {
      //新增/编辑模板
      this.$router.push({
        query: { id: id ? id : null, type: type },
        path: '/home/model'
      })
    },
    setTimer() {
      const that = this
      this.loading = true
      let timer = setTimeout(() => {
        if (sessionStorage.getItem('token')) {
          console.log('bbb',process.env.NODE_ENV); //development
          if (process.env.NODE_ENV === 'production') {
            let appinfo = sessionStorage.getItem('appInfo')
            if (appinfo) {
              let {
                depart_count,
                use_user_count,
                limit_user_count,
                user_count,
                expire_time,
                type_name,
                surplus_days,
                version_code
              } = JSON.parse(appinfo)
              that.totalDept = depart_count
              that.useUser = use_user_count
              that.buyUser = limit_user_count
              that.totalUser = user_count
              that.expireTime = expire_time
              that.type_name = type_name
              that.surplusDays = surplus_days
              if (version_code === 'trial' || version_code === 'free') {
                that.buttonNewopen = '开通'
              } else if (version_code === 'standard') {
                that.buttonRenew = '续费'
                that.buttonExpand = '扩容'
              }
            } else {
              getUserInfo()
                .then((res) => {
                  if (res.code === 200) {
                    clearInterval(timer) //清理定时任务
                    let data = res.data
                    that.totalDept = data.depart_count
                    that.useUser = data.use_user_count
                    that.buyUser = data.limit_user_count
                    that.totalUser = data.user_count
                    that.expireTime = data.expire_time
                    that.type_name = data.type_name
                    that.surplusDays = data.surplus_days
                    that.$store.commit('setKF',data.kefu_qr)
                    sessionStorage.setItem('appInfo', JSON.stringify(data))
                    if (
                      data.version_code === 'trial' ||
                      data.version_code === 'free'
                    ) {
                      that.buttonNewopen = '开通'
                    } else if (data.version_code === 'standard') {
                      that.buttonRenew = '续费'
                      that.buttonExpand = '扩容'
                    }
                  }
                })
                .catch((err) => {
                  this.showExpire = true
                })
            }
            // this.gitAppInfo()
          }

          that.getModelList().then(() => {
            that.loading = false
          })
        }
      }, 2000)
    },
    async getUser() {
      let { company, userId, username } = JSON.parse(
        sessionStorage.getItem('userInfo')
      )
      this.company = company
      this.userId = userId
      this.username = username ? username : '你好'
      this.allIsWx3 = sessionStorage.getItem('is_wx3')
      if (this.allIsWx3 === 'true') {
        if (/MicroMessenger/i.test(navigator.userAgent)) {
          let getConf = {}
          await getConfigApi({
            type: 1,
            current_url: window.location.origin + window.location.pathname
          }).then((res) => {
            getConf = res.data
          })
          const getConfig = {
            beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: getConf.corpid, // 必填，企业微信的corpID
            timestamp: getConf.timestamp, // 必填，生成签名的时间戳
            nonceStr: getConf.noncestr, // 必填，生成签名的随机串
            signature: getConf.signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
            jsApiList: ['WWOpenData']
          }
          await wxConfig(getConfig)
          const { data } = await getConfigApi({
            type: 2,
            current_url: window.location.origin + window.location.pathname
          })

          const config = {
            beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            corpid: data.corpid, // 必填，企业微信的corpID
            agentid: data.agentid,
            timestamp: data.timestamp, // 必填，生成签名的时间戳
            nonceStr: data.noncestr, // 必填，生成签名的随机串
            signature: data.signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
            jsApiList: ['WWOpenData']
          }

          wxAgentConfig(config).then((_) => {
            WWOpenData?.bindAll?.(document.querySelectorAll('ww-open-data'))
          })
        } else {
          otherBrowser()
        }
      }
    },
    getModelList() {
      return indexGetList()
        .then((res) => {
          if (res.code === 200) {
            this.modelData = res.data.slice(0, 3)
          } else {
            this.$message.error('未获取到数据，请稍后再试')
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    setModel() {
      this.$router.push('/home/workbench')
    },
    recovery(id) {
      recoveryModel({ id: id })
        .then((res) => {
          if (res.code === 200) {
            this.$message({
              type: 'success',
              message: res.message
            })
            this.getModelList(1, 100)
          } else {
            this.$message.error('操作失败，请重试')
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    handleDelModel(id, type) {
      if (type === 1) {
        delModel({ id }).then((res) => {
          if (res.code === 200) {
            this.$message({
              type: 'success',
              message: res.message
            })
            this.getModelList(1, 100)
          } else {
            this.$message.error('操作失败，请重试')
          }
        })
      } else {
        deletes({ id }).then((res) => {
          if (res.code === 200) {
            this.$message({
              type: 'success',
              message: res.message
            })
            this.getModelList(1, 100)
          } else {
            this.$message.error('操作失败，请重试')
          }
        })
      }
    },
    isUseModel(id) {
      this.dialogVisible = true
      this.useId = id
    },
    goToAsync() {
      this.$router.push('/home/set/asynchronous')
    },
    getUseModel() {
      this.modelLoading = true
      useModel({ id: this.useId })
        .then((res) => {
          if (res.code === 200) {
            this.$message({
              type: 'success',
              message: res.message
            })
            this.getModelList(1, 100)
          } else {
            this.$message.error('操作失败，请重试')
          }
          this.dialogVisible = false
          this.modelLoading = false
        })
        .catch((err) => {
          this.modelLoading = false
          this.dialogVisible = false
        })
    },
    closeThisModel(id) {
      closeModel({ id }).then((res) => {
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: res.message
          })
          this.getModelList(1, 100)
        } else {
          this.$message.error('操作失败，请重试')
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.index {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  .left-item,
  .right-item {
    min-height: 700px;
  }
  .left-item {
    flex: 1;
    display: flex;
    .main-center {
      width: 90%;
      min-width: 545px;
      margin: 40px auto;
    }

    .welcome {
      font-size: 20px;
      font-weight: 500;
    }
    .set-tips {
      width: 100%;
      height: 96px;
      background: #f5f8fc;
      margin: 33px 0;
      padding: 0 28px;
      .set-icon {
        width: 36px;
        margin-right: 20px;
      }
      .set-right {
        width: 100px;
        color: #fff;
        background: linear-gradient(180deg, #0082ef 0%, #006ac4 100%);
      }
    }
    .set-title {
      margin-bottom: 16px;
      color: #b3b3b3;
    }
    .model-list {
      flex-wrap: wrap;
      .model-item {
        margin: 17px 30px 42px 0;
        width: 224px;
        border: 1px solid #dbdbdb;
        border-radius: 10px 10px 0 0;
        box-shadow: 0 0 0 rgba(0, 0, 0, 0.16);
        .top-bg {
          height: 273px;
          width: 224px;
          position: relative;
          .bg-content {
            width: 100%;
            height: 273px;
            overflow: hidden;
            background: #f6f6f6;
            .top-bg-img {
              width: 100%;
            }
            .model-item-img {
              width: 214px;
              // height:235px;
              margin: 3px 5px;
            }
          }
          .flag {
            width: 56px;
            height: 24px;
            border-radius: 2px 0 16px 0;
            color: #ffffff;
            position: absolute;
            top: 0;
            left: 0;
            text-align: center;
            line-height: 24px;
          }
          .top-flag {
            background: #67c23a;
          }
          .model-flag {
            background: #fccf53;
          }
          .no-flag {
            background: #cfcfcf;
          }
          .del-flag {
            background: #ff3a3a;
          }
        }
        .vue-hover-mask_action {
          position: relative;
        }
        .vue-hover-mask {
          border-top-left-radius: 6px;
          border-top-right-radius: 6px;
          border-bottom-left-radius: 0px;
          border-bottom-right-radius: 0px;
        }
        .is-system {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 56px;
          margin-top: -32px;
          margin-left: -28px;
          .el-button.edit-model {
            margin-left: 0;
            margin-bottom: 5px;
          }
        }
        .edit-model {
          background: rgba(0, 0, 0, 0.1);
          color: #fff;
        }
        .el-dropdown {
          position: absolute;
          right: 15px;
          top: 15px;
        }
        .edit-more {
          background-color: #7f7c79;
          color: #fff;
          border-color: #7f7c79;
          height: 16px;
          font-size: 14px;
          width: 28px;
          line-height: 16px;
          border-radius: 10px;
        }
        .bot-name {
          background: #ffffff;
          line-height: 51px;
          padding-left: 15px;
          font-size: 14px;
          height: 51px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
    .app-list {
      flex-wrap: wrap;
      .app-item {
        flex: 1;
        margin-right: 20px;
        min-width: 300px;
        height: 78px;
        border: 1px solid #dbdbdb;
        border-radius: 4px;
        margin-bottom: 24px;
        padding-right: 28px;
        &:hover {
          box-shadow: 0 0 12px rgba(0, 0, 0, 0.12);
        }
        .set-icon {
          width: 48px;
          height: 48px;
          margin: 0 12px 0 20px;
        }
        .item-tips {
          flex: 1;
          .item-msg {
            color: #777777;
          }
        }
        .item-right {
          width: 64px;
        }
      }
    }
  }
  .right-item {
    flex: 0 0 340px;
    display: flex;
    font-size: 14px;
    border-left: 1px dashed #dbdbdb;
    .main-info {
      margin: 51px 40px 0;
    }
    .company {
      padding: 16px 0;
      font-size: 16px;
    }
    .line {
      width: 260px;
      height: 1px;
      background: linear-gradient(
        270deg,
        #ffffff 0%,
        #9a9a9a 51%,
        #ffffff 100%
      );
    }
    .use-data {
      padding: 24px 0 32px;
      .use-number {
        color: #2f7dcd;
      }
    }
    .use-title {
      font-weight: 500;
    }
    .use-time {
      padding: 10px 0 20px;
      .last-time {
        color: #ec5f50;
      }
    }
    .expend {
      border-top: 2px solid #e7e5e5;
      padding-top: 15px;
      .link-open {
        margin-top: 10px;
        padding: 10px 13px;
        background: rgba(24, 144, 255, 0.1);
        cursor: pointer;
        .link-icon {
          width: 20px;
          margin-right: 5px;
        }
      }
    }
  }
  .examples {
    opacity: 0.9;
  }
}
</style>